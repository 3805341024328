import React, { useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Grid, IconButton, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  useCalcTaskCreate,
  useExpansionProductList,
  useExpansionRead,
  useExpansionUpdate,
  useGlobalSettingsRead,
  useGlobalSettingsUpdate,
  useProductUpdate,
  useStockTaskCreate,
  useSyncTaskCreate,
} from "hooks";
import moment from "moment";
import {
  CreateCalcTaskInput,
  CreateStockTaskInput,
  CreateSyncTaskInput,
} from "types";
import { CustomButton, CustomLink, CustomTable, CustomTextButton } from "core";
import { DescriptionFormatter } from "components";
import { BoxContainer } from "layout/Container/BoxContainer";
import { getNextCalculationTime } from "modules/expansions/business";
import { EyeLineIcon, EyeLineOffIcon, OpenBrowserIcon } from "modules/icons";
import { IconColumnFormatter } from "modules/shared/columnformatters/IconColumnFormatter";
import { getIntervalDescription } from "modules/shared/options/IntervalTypeOption";
import { Routes } from "routes";
import { CalcIntervalFormDialog } from "../calcintervalForm/CalcIntervalFormDialog";
import utils from "utils";
import useStyles from "./styles";

export const ExpansionDetails: React.FC = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { expansion } = useExpansionRead();
  const { expansionProductList } = useExpansionProductList(
    true,
    false,
    expansion?.id,
  );
  const updateExpansionMutation = useExpansionUpdate();
  const updateProductMutation = useProductUpdate();

  // const { runningSyncTask } = useSyncTaskRunningList();

  const { globalSettings } = useGlobalSettingsRead();
  const updateGlobalSettingsMutation = useGlobalSettingsUpdate();
  const createCalcTaskMutation = useCalcTaskCreate();

  const createSyncTaskMutation = useSyncTaskCreate();
  const createStockTaskMutation = useStockTaskCreate();

  const [isCalcIntervalDialogOpen, setIsCalcIntervalDialogOpen] =
    useState<boolean>(false);

  const expansionActiveHandler = async () => {
    if (!expansion) return;

    const newActiveState = !expansion.isExpansionActive;

    updateExpansionMutation.mutate({
      id: expansion.id,
      isExpansionActive: newActiveState,
    });

    expansionProductList.forEach((product) => {
      updateProductMutation.mutate({
        id: product.id,
        expansionID: product.expansionID,
        isProductActive: newActiveState,
      });
    });

    searchParams.set("isProductActive", "all");
    sessionStorage.setItem(pathname, searchParams.toString());
    setSearchParams(searchParams);
  };

  const isTaskRunnig = useMemo(
    () =>
      Boolean(
        globalSettings?.runningTaskID &&
          globalSettings.runningTaskStartTime &&
          moment().diff(
            moment(globalSettings.runningTaskStartTime),
            "minutes",
          ) <= 15,
      ),
    [globalSettings],
  );

  const storePriceCalculationHandler = async () => {
    if (!expansion || !globalSettings || isTaskRunnig) return;

    const createCalcTaskInput: CreateCalcTaskInput = {
      taskType: "Manually",
      expansionID: expansion.id,
      status: "Pending",
      lastTriggered: new Date().toISOString(),
      startTime: new Date().toISOString(),
    };

    const createdCalcTask =
      await createCalcTaskMutation.mutateAsync(createCalcTaskInput);

    if (!createdCalcTask) {
      enqueueSnackbar("Preisberechnung konnte nicht gestartet werden!");
      return;
    }

    await updateGlobalSettingsMutation.mutateAsync({
      id: globalSettings.id,
      runningTaskType: "Calc",
      runningTaskID: createdCalcTask.id,
      runningTaskStartTime: new Date().toISOString(),
    });

    await updateExpansionMutation.mutateAsync({
      id: expansion.id,
      nextOnlinePriceCalc: getNextCalculationTime(
        expansion.intervalType,
        expansion.intervalValue,
      ),
    });
  };

  const synchronizationHandler = async () => {
    if (!expansion || !globalSettings || isTaskRunnig) return;

    const createSyncTaskInput: CreateSyncTaskInput = {
      taskType: "Sync",
      expansionID: expansion.id,
      status: "Pending",
      startTime: new Date().toISOString(),
      syncExpansionWithoutProducts: false,
    };

    const createdSyncTask =
      await createSyncTaskMutation.mutateAsync(createSyncTaskInput);

    if (!createdSyncTask) {
      enqueueSnackbar(
        "Synchronisation der Karten konnte nicht gestartet werden!",
      );
      return;
    }

    await updateGlobalSettingsMutation.mutateAsync({
      id: globalSettings.id,
      runningTaskType: "Sync",
      runningTaskID: createdSyncTask.id,
      runningTaskStartTime: new Date().toISOString(),
    });
  };

  const stockSynchronizationHandler = async () => {
    if (!expansion || !globalSettings || isTaskRunnig) return;

    const createStockTaskInput: CreateStockTaskInput = {
      taskType: "Stock",
      expansionID: expansion.id,
      status: "Pending",
      startTime: new Date().toISOString(),
    };

    const createdStockTask =
      await createStockTaskMutation.mutateAsync(createStockTaskInput);

    if (!createdStockTask) {
      enqueueSnackbar(
        "Synchronisation der Bestände konnte nicht gestartet werden!",
      );
      return;
    }

    await updateGlobalSettingsMutation.mutateAsync({
      id: globalSettings.id,
      runningTaskType: "Stock",
      runningTaskID: createdStockTask.id,
      runningTaskStartTime: new Date().toISOString(),
    });
  };

  return (
    <BoxContainer
      boxAlignment="center"
      paddingHorizontal={30}
      paddingVertical={30}
      paddingTop={true}
    >
      {expansion && (
        <CalcIntervalFormDialog
          dialogOpen={isCalcIntervalDialogOpen}
          setDialogOpen={setIsCalcIntervalDialogOpen}
          expansion={expansion}
        />
      )}
      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent="space-between"
      >
        <Grid item md={6}>
          <CustomTable
            tableTheme="default"
            marginBottom={0}
            tableClassName={classes.expansionDetilsTable}
          >
            <tbody>
              <tr>
                <td>
                  <Typography variant="h4">Editions-ID:</Typography>
                </td>
                <td>{expansion?.idExpansion}</td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Editions-Icon:</Typography>
                </td>
                <td>
                  <IconColumnFormatter expansion={expansion} />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Abkürzung:</Typography>
                </td>
                <td>{expansion?.abbreviation}</td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Anzahl Karten:</Typography>
                </td>
                <td>{expansion?.productsCount}</td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Edition aktiv:</Typography>
                </td>
                <td>
                  {expansion?.isExpansionActive ? "Ja" : "Nein"}
                  <IconButton
                    onClick={() => expansionActiveHandler()}
                    className={classes.iconButton}
                  >
                    {expansion?.isExpansionActive ? (
                      <EyeLineIcon className={classes.active} />
                    ) : (
                      <EyeLineOffIcon className={classes.inactive} />
                    )}
                  </IconButton>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Berechn.-Intervall:</Typography>
                </td>
                <td>
                  {expansion ? (
                    <CustomTextButton
                      text={getIntervalDescription(
                        expansion.intervalType,
                        expansion.intervalValue,
                      )}
                      onClick={() => setIsCalcIntervalDialogOpen(true)}
                      size="small"
                    />
                  ) : (
                    ""
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Onlinepreise:</Typography>
                </td>
                <td>
                  {expansion ? (
                    <CustomTextButton
                      text={
                        "Nächste Berechnung am " +
                        utils.dates.getDateInGermanFromAWSDateFormat(
                          expansion.nextOnlinePriceCalc,
                        )
                      }
                      onClick={() => setIsCalcIntervalDialogOpen(true)}
                      size="small"
                    />
                  ) : (
                    ""
                  )}
                  <br />
                  <span className={classes.dateFromNow}>zul.</span>{" "}
                  {expansion?.lastOnlinePriceCalc
                    ? utils.dates.getDateInGermanFromAWSDateFormat(
                        expansion.lastOnlinePriceCalc,
                      )
                    : ""}
                  {expansion?.lastOnlinePriceCalc && (
                    <span className={classes.dateFromNow}>
                      ({utils.dates.getFromNow(expansion.lastOnlinePriceCalc)})
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Synchronisation:</Typography>
                </td>
                <td>
                  <CustomButton
                    text="Synchronisiere Karten"
                    size="xsmall"
                    disabled={isTaskRunnig}
                    onClick={synchronizationHandler}
                  />
                  <br />
                  <span className={classes.dateFromNow}>zul.</span>{" "}
                  {expansion?.lastSynced
                    ? utils.dates.getDateInGermanFromAWSDateFormat(
                        expansion.lastSynced,
                      )
                    : ""}
                  {expansion?.lastSynced && (
                    <span className={classes.dateFromNow}>
                      ({utils.dates.getFromNow(expansion.lastSynced)})
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </CustomTable>
        </Grid>
        <Grid item md={6}>
          <CustomTable
            tableTheme="default"
            marginBottom={0}
            tableClassName={classes.expansionDetilsTable}
          >
            <tbody>
              <tr>
                <td>
                  <Typography variant="h4">Alias:</Typography>
                </td>
                <td>
                  <DescriptionFormatter
                    description={expansion?.expansionAlias}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">DE-Name:</Typography>
                </td>
                <td>{expansion?.expansionDeName}</td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">EN-Name:</Typography>
                </td>
                <td>{expansion?.expansionEnName}</td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Parent:</Typography>
                </td>
                <td>
                  {expansion?.parentID ? (
                    <CustomLink
                      link={Routes.expansions.subNavigations.expansion.path.replace(
                        ":expansionID",
                        expansion.parentID,
                      )}
                    />
                  ) : (
                    <DescriptionFormatter description={expansion?.parentID} />
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Release:</Typography>
                </td>
                <td>
                  {expansion?.releaseDate
                    ? utils.dates.getDateInGermanFromAWSDateFormat(
                        expansion.releaseDate,
                      )
                    : ""}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Webseite:</Typography>
                </td>
                <td>
                  <a
                    href={
                      "https://www.cardmarket.com" + expansion?.expansionWebsite
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.websiteLink}
                  >
                    {expansion?.expansionEnName}
                    <OpenBrowserIcon />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Ladenpreise:</Typography>
                </td>
                <td>
                  <CustomButton
                    text="Aktualisiere Laden- und Onlinepreise"
                    size="xsmall"
                    disabled={isTaskRunnig}
                    onClick={storePriceCalculationHandler}
                  />
                  <br />
                  <span className={classes.dateFromNow}>zul.</span>{" "}
                  {expansion?.lastStorePriceCalc
                    ? utils.dates.getDateInGermanFromAWSDateFormat(
                        expansion.lastStorePriceCalc,
                      )
                    : ""}
                  {expansion?.lastStorePriceCalc && (
                    <span className={classes.dateFromNow}>
                      ({utils.dates.getFromNow(expansion.lastStorePriceCalc)})
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="h4">Bestände:</Typography>
                </td>
                <td>
                  <CustomButton
                    text="Aktualisiere Bestände"
                    size="xsmall"
                    disabled={isTaskRunnig}
                    onClick={stockSynchronizationHandler}
                  />
                  <br />
                  <span className={classes.dateFromNow}>zul.</span>{" "}
                  {expansion?.lastStockSynced
                    ? utils.dates.getDateInGermanFromAWSDateFormat(
                        expansion.lastStockSynced,
                      )
                    : ""}
                  {expansion?.lastStockSynced && (
                    <span className={classes.dateFromNow}>
                      ({utils.dates.getFromNow(expansion.lastStockSynced)})
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </CustomTable>
        </Grid>
      </Grid>
    </BoxContainer>
  );
};
