import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import { useExpansionList } from "hooks";
import { Product, SearchTextParamName, ValueSubmitTrigger } from "types";
import { CustomTable, Loading, SortableHeader } from "core";
import { CheckIcon, CrossIcon } from "components";
import { PriceCalculationIcon } from "modules/icons";
import { ProductActiveColumnFormatter } from "modules/shared/columnformatters/ProductActiveColumnFormatter";
import { ProductAliasColumnFormatter } from "modules/shared/columnformatters/ProductAliasColumnFormatter";
import { WebsiteColumnFormatter } from "modules/shared/columnformatters/WebsiteColumnFormatter";
import { ExpansionProductNumberColumn } from "../ColumnFormatters/ProductNumberColumn";
import { ProductPriceCalculationMethodDialog } from "../PriceCalculation/ProductPriceCalculationMethodDialog";
import useStyles from "./styles";

type ExpansionProductListTableProps = {
  isLoading: boolean;
  productList: Product[];
};

export const ExpansionProductListTable: React.FC<
  ExpansionProductListTableProps
> = ({ isLoading, productList }) => {
  const { classes, cx } = useStyles();

  const { expansionList } = useExpansionList(false);

  const [searchParams] = useSearchParams();

  const expansionID = searchParams.get("expansionID");

  const [productToShowCalculationMethod, setProductToShowCalculationMethod] =
    useState<Product | null>(null);
  const [showCalculationMethodDialogOpen, setShowCalculationMethodDialogOpen] =
    useState(false);

  const [selectedRow, setSelectedRow] = useState<Product | null>(null);

  const resetSelection = () => {
    setSelectedRow(null);
  };

  const isProductSelected = (product: Product) =>
    selectedRow?.id === product.id;

  const productSelectHandler = (product: Product) => {
    console.log("productSelectHandler", product);
    setSelectedRow(product);
  };

  const handleProductSelection = (
    rowIndex: number,
    trigger: ValueSubmitTrigger,
  ) => {
    const isFirstRow = rowIndex === 0;
    const isLastRow = rowIndex === productList.length - 1;

    switch (trigger) {
      case ValueSubmitTrigger.Enter:
        if (isLastRow) {
          resetSelection();
        } else {
          setSelectedRow(productList[rowIndex + 1]);
        }
        break;
      case ValueSubmitTrigger.Blur:
      case ValueSubmitTrigger.Escape:
        resetSelection();
        break;
      case ValueSubmitTrigger.ShiftTab:
      case ValueSubmitTrigger.ArrowLeft:
      case ValueSubmitTrigger.ArrowUp:
        if (!isFirstRow) {
          setSelectedRow(productList[rowIndex - 1]);
        }
        break;
      case ValueSubmitTrigger.Tab:
      case ValueSubmitTrigger.ArrowRight:
      case ValueSubmitTrigger.ArrowDown:
        if (!isLastRow) {
          setSelectedRow(productList[rowIndex + 1]);
        }
        break;
    }
  };

  return (
    <>
      {productToShowCalculationMethod && (
        <ProductPriceCalculationMethodDialog
          dialogOpen={showCalculationMethodDialogOpen}
          setDialogOpen={setShowCalculationMethodDialogOpen}
          product={productToShowCalculationMethod}
        />
      )}

      <CustomTable
        marginBottom={0}
        uppercaseHeaders={false}
        fixedLayout={productList.length !== 0}
      >
        <colgroup>
          <col width="10px" />
          <col width="10px" />
          <col width="10px" />
          <col width="90px" />
          <col width="90px" />
          <col width="50px" />
          <col width="20px" />
          <col width="20px" />
          <col width="10px" />
          <col width="10px" />
          <col width="10px" />
        </colgroup>

        <thead>
          <tr>
            <SortableHeader
              headerID="website"
              header="CM"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="rarity"
              header="R"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="expansionID"
              header="Exp"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="productDeName"
              header="DE-Name"
              isDefaultSort={true}
              defaultSortOrder="asc"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="productEnName"
              header="EN-Name"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="productAlias"
              header="Alias"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
            />
            <SortableHeader
              headerID="priceOldStore"
              header="Preis alt"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
              textAlign="center"
            />
            <SortableHeader
              headerID="priceStore"
              header="Preis neu"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
              textAlign="center"
            />

            <th align="center">R.weg</th>

            <SortableHeader
              headerID="lastStorePriceCalcSuccess"
              header="Erf."
              dataLength={productList.length}
              isLoadingDataList={isLoading}
              textAlign="center"
            />

            <SortableHeader
              headerID="isProductActive"
              header="Aktiv"
              dataLength={productList.length}
              isLoadingDataList={isLoading}
              textAlign="center"
            />
          </tr>
        </thead>
        <tbody>
          {productList.length === 0 ? (
            <tr>
              <td colSpan={99}>
                <div className={classes.noResults}>
                  {isLoading ? (
                    <Loading
                      size="25px"
                      description={
                        "Bitte warten. " +
                        (!expansionID || expansionID === "search"
                          ? "Suche wird durchgeführt..."
                          : "Karten der Edition werden geladen...")
                      }
                      style={{ margin: 0 }}
                    />
                  ) : expansionID === "search" &&
                    !searchParams.get(SearchTextParamName.SearchProduct) ? (
                    "Bitte geben Sie einen Suchbegriff ein!"
                  ) : expansionID ? (
                    "Keine Produkte gefunden"
                  ) : (
                    "Bitte wählen Sie eine Edition aus oder geben Sie einen Suchbegriff ein!"
                  )}
                </div>
              </td>
            </tr>
          ) : (
            productList.map((product, rowIndex) => {
              const expansion = expansionList.find(
                (expansion) => expansion.id === product.expansionID,
              );

              if (!expansion) {
                return null;
              }

              const priceCalcExists = Boolean(
                product.storePriceCalcID ||
                  product.storePriceOldCalcID ||
                  product.onlinePriceCalcID ||
                  product.onlinePriceOldCalcID,
              );

              const priceSuccess =
                product.lastStorePriceCalcSuccess ||
                product.priceStoreSetManually;

              const rowClass =
                rowIndex % 2 === 0
                  ? priceSuccess
                    ? classes.evenRowDefault
                    : classes.evenRowError
                  : priceSuccess
                    ? classes.oddRowDefault
                    : classes.oddRowError;

              return (
                <tr key={product.id} className={classes.productItem}>
                  <td width="10px">
                    <WebsiteColumnFormatter product={product} />
                  </td>
                  <td width="10px">{product.rarity.charAt(0)}</td>
                  <td width="10px">{expansion?.abbreviation ?? ""}</td>
                  <td width="200px" className={classes.noWrap}>
                    <div className={classes.productName}>
                      {product.productAlias ?? product.productDeName}
                    </div>
                  </td>
                  <td width="200px" className={classes.noWrap}>
                    <div className={classes.productName}>
                      {product.productEnName}
                    </div>
                  </td>
                  <td width="200px" className={classes.noWrap}>
                    <ProductAliasColumnFormatter
                      product={product}
                      key={product.id}
                    />
                  </td>
                  <td align="right">
                    {product.priceOldStore
                      ? product.priceOldStore.toLocaleString("de-DE", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""}
                  </td>
                  <td
                    width="30px"
                    onClick={() => productSelectHandler(product)}
                    className={cx(
                      classes.numberColumn,
                      priceSuccess
                        ? classes.numberColumnDefault
                        : classes.numberColumnError,
                      isProductSelected(product)
                        ? classes.numberColumnSelected
                        : "",
                      rowClass,
                    )}
                  >
                    <ExpansionProductNumberColumn
                      product={product}
                      rowIndex={rowIndex}
                      selectedRow={selectedRow}
                      handleProductSelection={handleProductSelection}
                      decimalScale={2}
                      numberFormatOptions={{
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }}
                    />
                  </td>
                  <td align="center">
                    <IconButton
                      onClick={() => {
                        setProductToShowCalculationMethod(product);
                        setShowCalculationMethodDialogOpen(true);
                      }}
                      className={classes.calcMethodButton}
                      disabled={!priceCalcExists}
                    >
                      <PriceCalculationIcon
                        className={
                          !priceCalcExists ? classes.priceCalcDisabled : ""
                        }
                      />
                    </IconButton>
                  </td>
                  <td align="center">
                    {priceSuccess ? <CheckIcon /> : <CrossIcon />}
                  </td>
                  <td align="center">
                    <ProductActiveColumnFormatter
                      key={product.id}
                      product={product}
                      expansionList={expansionList}
                    />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </CustomTable>
    </>
  );
};
