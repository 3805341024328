import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useExpansionList } from "hooks";
import { SyncTask } from "types";
import {
  CustomLink,
  CustomReactTable,
  CustomReactTableHandles,
  CustomTag,
} from "core";
import { getExpansionName } from "modules/expansions/business";
import {
  getTaskStatusColor,
  getTaskStatusName,
} from "modules/shared/options/TaskStatusOption";
import { Routes } from "routes";
import { useSyncTaskListUIContext } from "../SyncTaskListUIContext";
import utils from "utils";

type SyncTaskListTableProps = {
  syncTaskTableRef?: React.RefObject<CustomReactTableHandles<SyncTask>>;
};

export const SyncTaskListTable: React.FC<SyncTaskListTableProps> = ({
  syncTaskTableRef,
}) => {
  const { expansionList } = useExpansionList(false);

  const syncTaskListUIContext = useSyncTaskListUIContext();
  const syncTaskListUIProps = useMemo(() => {
    return {
      syncTaskList: syncTaskListUIContext.syncTaskList,
      isLoadingSyncTaskList: syncTaskListUIContext.isLoadingSyncTaskList,
    };
  }, [syncTaskListUIContext]);

  // Table columns
  const defaultColumns = React.useMemo<ColumnDef<SyncTask>[]>(
    () => [
      {
        header: "Edition",
        accessorKey: "expansionID",
        cell: ({ row }) => {
          const expansion = expansionList.find(
            (expansion) => expansion.id === row.original.expansionID,
          );

          const expansionName = expansion
            ? getExpansionName(expansion)
            : "Edition " + row.original.expansionID.replace("exp_", "");

          return (
            <CustomLink
              link={Routes.expansions.subNavigations.expansion.path.replace(
                ":expansionID",
                row.original.expansionID,
              )}
              linkText={expansionName}
            />
          );
        },
        footer: (props) => props.column.id,
        enableSorting: false,
        meta: {
          getRawValue: (row) => {
            if (!row.original.expansionID) return "Neue Edition";

            const expansion = expansionList.find(
              (expansion) => expansion.id === row.original.expansionID,
            );

            const expansionName = expansion
              ? getExpansionName(expansion)
              : "Edition " + row.original.expansionID.replace("exp_", "");

            return expansionName;
          },
        },
      },
      {
        header: "Start",
        accessorKey: "startTime",
        accessorFn: (row) =>
          utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
            row.startTime,
          ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
              row.original.startTime,
            ),
        },
      },
      {
        header: "Ende",
        accessorKey: "endTime",
        accessorFn: (row) =>
          row.endTime
            ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
                row.endTime,
              )
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            row.original.endTime
              ? utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(
                  row.original.endTime,
                )
              : "",
        },
      },
      {
        header: "Dauer",
        accessorKey: "duration",
        accessorFn: (row) =>
          utils.dates.getDurationFromAWSDateTimeFormat(
            row.startTime,
            row.endTime,
          ),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) =>
            utils.dates.getDurationFromAWSDateTimeFormat(
              row.original.startTime,
              row.original.endTime,
            ),
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ row }) => (
          <CustomTag
            tagColor={getTaskStatusColor(row.original.status)}
            content={getTaskStatusName(row.original.status)}
          />
        ),
        footer: (props) => props.column.id,
        meta: {
          getRawValue: (row) => getTaskStatusName(row.original.status),
          align: "center",
        },
      },
    ],
    [expansionList],
  );

  return (
    <CustomReactTable<SyncTask>
      data={syncTaskListUIProps.syncTaskList}
      columns={defaultColumns}
      isLoadingDataList={syncTaskListUIProps.isLoadingSyncTaskList}
      ref={syncTaskTableRef}
      tableTheme="default"
      leftSpacing="large"
    />
  );
};
